<script setup>
import { Link } from '@inertiajs/vue3';
import ArrowRight from '@assets/icons/arrow_right.svg';
import ArrowLeft from '@assets/icons/arrow_left.svg';
import { ref } from 'vue';
import { useScroll } from '@vueuse/core';

const scrollableContainer = ref(null);
const { x, arrivedState } = useScroll(scrollableContainer, { behavior: 'smooth' });

</script>

<template>
    <div class="relative">
        <article ref="scrollableContainer" class="w-full overflow-x-auto snap-x flex gap-2 py-4 text-primary sm:no-scrollbar">
            <div
                v-for="productType in $page.props.shop.productTypes"
                :key="productType.id"
            >
                <Link
                    :href="route('shop.index', { productTypeIds: [productType.id] })"
                    class="snap-start w-48 mx-1 inline-flex flex-col items-center gap-1 font-mono text-xs text-primary"
                >
                    <img
                        :src="productType.image"
                        class="w-full aspect-[5/6] object-cover rounded-[2rem] hover:border-2 hover:border-primary"
                        :alt="productType.title"
                    >
                    {{ productType.title }}
                </Link>

            </div>

        </article>
        <div v-show="x > 10" class="hidden sm:block h-12 absolute top-0 bottom-0 left-0 my-auto">
            <ArrowLeft
                class="shrink-0 h-12 p-2 opacity-60 hover:opacity-100 cursor-pointer rounded-full bg-primary text-primary-content transition-all"
                @click="x -= 600"
            />
        </div>
        <div v-show="! arrivedState.right" class="hidden sm:block h-12 absolute top-0 bottom-0 right-0 my-auto">
            <ArrowRight
                class="shrink-0 h-12 p-2 opacity-60 hover:opacity-100 cursor-pointer rounded-full bg-primary text-primary-content transition-all"
                @click="x += 600"
            />
        </div>
    </div>
</template>
